import "../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.15_@types+node@20.17.10_babel-plugin-macros@3.1.0_terser@_up4y6fjbw7lo554abzy63tgjke/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fnavs%2FTopNavButton.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA81U3XKjIBS%2Bz1Nwk5k6s3Ri1iSGvkjvOkTQ0CKHAiZ2d%2Fbdd0SNYrTd3u2dwJHz%2FZyPx5d0n2amjtHvFUInmr0VBirFCFKg%2BFOzB4ZxM6yhclIoPmxoyphQBUEXah4wfjnsy%2FOFX2TUHGaVsWAI0iCU42a4EBvKRGUnf1230dPqz%2BqxQ7W9QxVUAwuqf%2F5HHBByvHaY8QwMdQJU32uAm3yL3O7z6vrqm14Fc2eCSlrjDJTjygXs4q2uJxTB%2F8eE1ZJ%2BEFQYwZqNguq2fCxfSPMYIQtSsAnsJPpSoE1XUmN7pgyuk%2FN4cwyo7z31e4hGMOx4qSV1HGcgq1JZgpJU1yjOzR2LBUMQolIUCgvHS0tQxnuPJ0My4DnM4%2Bm0b%2Fo3yzMXxdkN69fKOpF%2F9L6MOy0AWDRbZdF3bVH8a1viQPXUs8yhgRrU%2FXpvUwESps3rIrji6K%2BYLQyHm%2F5r4WlhFBqf56K7oOzC6Ixi007Q0DjzjXtPO0s7x9NxSm7aTrIT6%2FqzuNysxrMqtOnWYEU7uvRkQVaOB9nNJfctcyHl%2FAXWGXjj04cmGZ3hjlMccDqBc1BO31NsWjX6XQeaILzv0maosjmYkrSfjdIPeLdZ%2F0CHdYQMuGYj2TEezgzzQncoOpztSftq9BbcUu2b9qt5hSTPHUG7zXoJ2LNH1uD4C1B36vQQBwAA%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var arrowCx = '_868crxc';
export var iconContainerCx = '_868crx7';
export var itemTitleCx = _7a468({defaultClassName:'_868crx8',variantClassNames:{isHovered:{true:'_868crx9'},isFocusVisible:{true:'_868crxa'}},defaultVariants:{},compoundVariants:[]});
export var linkButtonCx = _7a468({defaultClassName:'_868crx3',variantClassNames:{isTriggerActive:{true:'_868crx4'}},defaultVariants:{},compoundVariants:[]});
export var linkCx = '_868crx6';
export var menuItemsContainerCx = '_868crx5';
export var titleWithLinkCx = '_868crxb';
export var trapezoidCx = '_868crxd';
export var trapezoidWidthDiff = 'var(--_868crx0)';
export var triggerButtonCx = _7a468({defaultClassName:'_868crx1',variantClassNames:{isTriggerActive:{true:'_868crx2'}},defaultVariants:{},compoundVariants:[]});