import { assignInlineVars } from '@vanilla-extract/dynamic'
import clsx from 'clsx'
import { ComponentPropsWithoutRef, PropsWithChildren, forwardRef } from 'react'
import { Maybe, TestProps } from 'typ'
import { isNil, isNotNil } from 'typeguards'
import {
  AlignItems,
  Columns,
  Display,
  FlexDirection,
  FlexWrap,
  JustifyContent,
  PlaceItems,
  Spacing,
  sprinkles,
} from 'vanilla-extract-config/sprinkles'
import { BoxLayoutProps } from '../../types'
import { extractBoxLayoutProps } from '../../utils'
import { columnsCx, columnsVar, rowsCx, rowsVar } from './Grid.css'

export type GridOwnProps = {
  align?: AlignItems
  as?: React.ElementType
  columnGap?: Spacing
  columns?: Columns | string
  rows?: Columns | string
  direction?: FlexDirection
  display?: Extract<Display, 'grid' | 'none'>
  flexWrap?: FlexWrap
  justify?: JustifyContent
  place?: PlaceItems
  rowGap?: Spacing
  gap?: Spacing
}

export type GridProps = PropsWithChildren &
  Omit<ComponentPropsWithoutRef<'div'>, 'className'> &
  TestProps &
  BoxLayoutProps &
  GridOwnProps & {
    className?: never
  }

const getGridTemplateValue = (value: Maybe<Columns | string>) => {
  return isNil(value) ? undefined : typeof value === 'number' ? `repeat(${value}, 1fr)` : value
}
export const Grid = forwardRef<HTMLDivElement, GridProps>(
  (
    {
      align,
      as: Component,
      children,
      className,
      columnGap,
      columns,
      display = 'grid',
      flexWrap,
      justify,
      place,
      rowGap,
      rows,
      gap,
      ...props
    },
    ref
  ) => {
    const Element = Component || 'div'

    const columnsCssValue = getGridTemplateValue(columns)
    const rowsCssValue = getGridTemplateValue(rows)

    const hasCols = isNotNil(columns)
    const hasRows = isNotNil(rows)
    const { layoutProps, testProps, colorProps, ...coreProps } = extractBoxLayoutProps(props)
    return (
      <Element
        {...testProps}
        {...coreProps}
        className={clsx([
          sprinkles({
            ...layoutProps,
            ...colorProps,
            align,
            columnGap,
            display,
            flexWrap,
            justify,
            place,
            rowGap,
            gap,
          }),
          className,
          hasCols ? columnsCx : undefined,
          hasRows ? rowsCx : undefined,
        ])}
        style={assignInlineVars({ [columnsVar]: columnsCssValue, [rowsVar]: rowsCssValue })}
        ref={ref}
      >
        {children}
      </Element>
    )
  }
)

Grid.displayName = 'Grid'
